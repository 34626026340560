/*
 * @Author: your name
 * @Date: 2022-03-04 10:52:57
 * @LastEditTime: 2022-03-29 11:44:01
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vi\src\api\comments.js
 */
import request from '@/utils/request'

// 获取第三方登录数据
export function THIRD_LOGIN(data) {
  return request({
      method: 'GET',
      url: 'users/third/login',
      params: data
  })
}

// 获取评论列表
export function commentList(data) {
    return request({
        method: 'GET',
        url: 'find/lists',
        params: data
    })
}

// 获取评论
export function commentSlide(data) {
    return request({
        method: 'GET',
        url: 'content/slide',
        params: data
    })
}

// 点赞
export function clickGood(data) {
    return request({
        method: 'GET',
        url: 'find/good',
        params: data
    })
}

// 发布评论
export function publicReivew(data) {
    return request({
        method: 'GET',
        url: 'find/comment/add',
        params: data
    })
}

// 用户发布消息
export function userPublish(data) {
    return request({
        method: 'POST',
        url: 'find/add',
        data
    })
}

// 发布主题
export function Publishtheam() {
    return request({
        method: 'GET',
        url: 'find/activities'
    })
}

// 单một nơi用户晒单内容
export function CommentItem(data) {
    return request({
        method: 'GET',
        url: 'find/comment',
        params: data
    })
}

// 获取展示节点
export function GetIntroduce(){
    return request({
        method: 'GET',
        url: 'content/sale',
        params: {position: 'item'}
    })
}

// 获取分享页banner
export function getBannerOfShare(){
    return request({
        method: 'GET',
        url: 'content/slide',
        params: {position: 'agent'}
    })
}
